import React from 'react';
// import FooderControl from '../components/FooderControl';

// icons
import homeIcon from '../../icons/home.png';
import searchIcon from '../../icons/search.png';
import userAltIcon from '../../icons/profile.png';

import '../../styles/nav.css';

import estiLogo from '../../icons/esti.svg';

function Fooder(props) {
    return (
        <footer className="footer">
            <nav className="footer_nav desktop">
                <ul className="nav_list desktop_hide">
                    <li
                        className={`nav_item ${props.activeTab === 'home' ? 'nav_active' : ''}`}
                        onClick={() => props.handleTabChange('home')}
                    >
                        <img src={homeIcon} alt="Home" className="nav_icon" />
                    </li>
                    <li
                        className={`nav_item ${props.activeTab === 'books' ? 'nav_active' : ''}`}
                        onClick={() => props.handleTabChange('books')}
                    >
                        <img src={searchIcon} alt="Books" className="nav_icon" />
                    </li>
                    <li
                        className={`nav_item ${props.activeTab === 'user' ? 'nav_active' : ''}`}
                        onClick={() => props.handleTabChange('user')}
                    >
                        <img
                            src={userAltIcon}
                            className="nav_icon"
                            alt='user'
                        />
                    </li>
                </ul>
            </nav>
        </footer>
    );
}

export default Fooder;